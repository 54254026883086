import React from 'react';
import './Arrow.scss';
import { FaChevronLeft, FaChevronRight } from "react-icons/fa";

interface IArrow {
  direction: 'right' | 'left',
  handleClick: () => void
}

const Arrow = ({ direction, handleClick }: IArrow) => (
  <div className="Slider__Arrow"
    onClick={handleClick}
    style={direction === 'right' ? {} : { textAlign: 'right' }}
  >
    {direction === 'right' ? <FaChevronRight /> : <FaChevronLeft />}
  </div>
);

export default Arrow;