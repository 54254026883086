import React from 'react'
import { JSXChildren } from '../../../utils';

interface ISliderContent {
  children?: JSXChildren;
}
const SliderContent = ({ children }: ISliderContent) => {
  return (
    <div className="SliderContent">
      {children}
    </div>
  );
};

export default SliderContent