import React from "react"
import { graphql } from "gatsby"

import '../fonts/BogleWeb-Bold.ttf';
import '../fonts/BogleWeb-Regular.ttf';
import ResourceHeading from '../core/components/ResourceHeading/ResourceHeading';
import ResourceList from '../core/components/ResourceList/ResourceList';
import Slider from '../common/components/Slider/Slider';
import SEO from '../core/components/SEO/seo';
import { Subject } from "rxjs";
import { ICard } from '../common/components/Card/Card';
import { ISlide } from '../../models/slide';
import { Constants } from '../common/constants';
import detectIframe from "../common/services/detect-iframe.service";

interface LandingPage {
  data: {
    site: {
      siteMetadata: {
        env: string
      }
    },
    allWordpressWpResources: {
      nodes: {
        id: string;
        wordpress_id: number;
        slug: string;
        acf: {
          master_resource?: boolean;
          resource_title: string;
          resource_subtitle: string;
          resource_logo: {
            source_url: string;
          }
        }
      }[];
    }
    wordpressPage: {
      title: string;
      acf: {
        headline: string;
        all_resources_headline: string;
        all_resources_description: string;
        slider: {
          description: string;
          location: string;
          name: string;
          store: string;
          background_image: {
            source_url: string;
          }
        }[];
      }
      yoast_meta: {
        yoast_wpseo_title: string;
        yoast_wpseo_metadesc: string;
        yoast_wpseo_facebook_image: string;
        yoast_wpseo_facebook_description: string;
        yoast_wpseo_facebook_title: string;
        yoast_wpseo_twitter_title: string;
        yoast_wpseo_twitter_description: string;
        yoast_wpseo_twitter_image: string;
      }
    }
  }
}

const Homepage = (props: LandingPage) => {
  const data = props.data;
  const acf = data.wordpressPage.acf;
  const seo = data.wordpressPage.yoast_meta
  const env = data.site.siteMetadata.env

  if(!detectIframe(Constants.WalmartCaresBaseUrl, env)) {
    return (null)
  }


  const resources = data.allWordpressWpResources.nodes
    .filter(x => x.acf.master_resource)
    .map(x => {
      const resource: ICard = {
        imageUrl: x.acf.resource_logo && x.acf.resource_logo.source_url ? x.acf.resource_logo.source_url: "",
        linkUrl: `${Constants.Paths.resources}${x.slug}`,
        title: x.acf.resource_subtitle,
        subtitle: x.acf.resource_title,
        buttonEvent: new Subject<void>()
      }

      return resource;
    });

  const slides = acf.slider
    .map(x => {
      const slide: ISlide = {
        quote: x.description,
        address2: x.location,
        author: x.name,
        address1: x.store,
        backgroundImage: x.background_image && x.background_image.source_url ? x.background_image.source_url :"",
      }

      return slide;
    });
  const noEmptySlider =  false;
  return(
    <>
      <SEO
        title={`${data.wordpressPage.title} - My Walmart Cares`}
        description={seo.yoast_wpseo_metadesc}
        image={seo.yoast_wpseo_facebook_image ? seo.yoast_wpseo_facebook_image.source_url : ''}
        twitterDescription={seo.yoast_wpseo_twitter_description}
        twitterTitle={seo.yoast_wpseo_twitter_title}
        twitterImage={seo.yoast_wpseo_twitter_image ? seo.yoast_wpseo_twitter_image.source_url : ''}
        facebookDescription={seo.yoast_wpseo_facebook_description}
        facebookTitle={seo.yoast_wpseo_facebook_title}
      />
      <ResourceHeading title={acf.headline} />
      {noEmptySlider &&
      <Slider slides={displaySlides} />
      }
      <ResourceList
        list={resources}
        backToHome={true}
      />
    </>
  )
};

export default Homepage;
export const pageQuery = graphql`
  query {
    site {
      siteMetadata {
        env
      }
    }
    allWordpressWpResources {
      nodes {
        id
        wordpress_id
        slug
        acf {
          master_resource
          resource_title
          resource_subtitle
          resource_logo {
            source_url
          }
        }
      }
    }
    wordpressPage(wordpress_id: { eq: 111 }){
      title
      acf {
        headline
        all_resources_headline
        all_resources_description
        slider {
          description
          name
          store
          location        
        }
      }
      yoast_meta {
        yoast_wpseo_title
        yoast_wpseo_metadesc
        yoast_wpseo_facebook_image
        yoast_wpseo_facebook_title
        yoast_wpseo_facebook_description
        yoast_wpseo_twitter_title
        yoast_wpseo_twitter_description
        yoast_wpseo_twitter_image
      }
    }
  }
`;
