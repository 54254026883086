import React from 'react';
import { FaCircle } from "react-icons/fa";
import './Single.scss';
import CommonUtils from '../../../utils';

const SingleControl = (props: { current: number; index: number; handleClick: (index: number) => void }) => {
  const { index, handleClick, current } = props;
  const handleInternalClick = () => handleClick(index);

  const className = CommonUtils.buildClassesString({
    "Slider__SingleControl": true,
    "Slider__SingleControl-active": current === index
  });

  return (
    <div className={className}
      onClick={handleInternalClick}>
      <FaCircle />
    </div>
  );
};

export default SingleControl;