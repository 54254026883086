import React from 'react';
import './Item.scss';
import { ISlide } from '../../../models/slide';
import CommonUtils, { JSXChildren } from '../../../utils';

interface ISliderItem extends ISlide {
  current: boolean;
  children?: JSXChildren
}
const SliderItem = (props: ISliderItem) => {
  const { backgroundImage, current, quote, author, address1, address2, children } = props;
  const className = (name: string) => `${name} ${CommonUtils.buildClassesString({ 'Active': current })}`;
  return (
    <>
      <div className={className('SliderItem')} style={
        {
          opacity: current ? '1' : '0',
          backgroundImage: `linear-gradient(0deg, rgba(4, 30, 66, 0.5), rgba(4, 30, 66, 0.5)), url(${backgroundImage})`
        }
      } />
      <div className={className('SliderItem__content-container')}>
        <div className="SliderItem__content" style={{ padding: '0 10vw' }}>
          <span className='SliderItem__title'>
            <strong>{quote}</strong>
          </span>
          <p className="SliderItem__subtitle">
            <strong>{author}</strong> <br />
            {address1}<br />
            {address2}
          </p>
          {children}
        </div>
      </div>
    </>
  );
};

export default SliderItem;