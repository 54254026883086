import React from 'react';
import Arrow from './Arrow';
import SingleControl from './Single';
import './Container.scss';

interface IControlsContainer {
  length: number,
  current: number,
  leftFunction: () => void,
  rightFunction: () => void,
  singleFunction: (index: number) => void
}

const ControlsContainer = (props: IControlsContainer) => {
  const { length, current, leftFunction, rightFunction, singleFunction } = props;
  const buildDotControls = () =>
    new Array(length).fill(0)
      .map((_, i) => <SingleControl key={i + 1} current={current} index={i} handleClick={singleFunction} />)
  return (
    <div className="Slider__controls">
      <Arrow direction="left" handleClick={leftFunction} />
      {buildDotControls()}
      <Arrow direction="right" handleClick={rightFunction} />
    </div>
  );
}

export default ControlsContainer;