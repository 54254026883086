import { useEffect, useState } from 'react';

export default function useImagePreloader(...images: string[]) {
  const [loadedImages, setLoadedImages] = useState(false);

  useEffect(() => {
    Promise.all(images.map(x => {
      return new Promise<string>((resolve, reject) => {
        const img = new Image();
        img.onload = () => resolve(x);
        img.onerror = () => reject();

        img.src = x;
      })
    })).then(
      () => setLoadedImages(true),
      () => setLoadedImages(false))
  });

  return loadedImages;
}