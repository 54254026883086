import { Constants } from "../constants";

/**
 * @param navigateUrl {string} navigate to this url if app was not embedded to iframe
 * @param currentEnv {string} navigate to url only for production environment
 * @returns {boolean} returns true or false if app embedded to iframe
 */

const detectIframe = (navigateUrl: string, currentEnv: string): boolean => {
  const isEmbedded = () => {
    try {
      if(currentEnv !== Constants.productionEnvName) {
        return true
      }
    
      return window.self !== window.top
    } catch (e) {
      return true;
    }
  }
    
  const isIframe = isEmbedded()
    
  if(!isIframe) {
    window.location.replace(navigateUrl)
  }
    
  return isIframe
}

export default detectIframe