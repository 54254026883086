import React, { useState } from 'react'
import './Slider.scss';
import SliderContent from './Content/Content';
import SliderItem from './Item/Item';
import useImagePreloader from '../../hooks/image-preolad';
import ControlsContainer from './Controls/Container';
import { ISlide } from '../../models/slide';

interface ISlider {
  slides: ISlide[]
}

const Slider = ({ slides }: ISlider) => {
  const wasLoaded = useImagePreloader(...slides.map(x => x.backgroundImage));
  const [activeIndex, setActiveIndex] = useState(0);
  const nextSlide = () => {
    if (activeIndex === slides.length - 1) {
      return setActiveIndex(0);
    }

    setActiveIndex(activeIndex + 1);
  }

  const prevSlide = () => {
    if (activeIndex === 0) {
      return setActiveIndex(slides.length - 1);
    }

    setActiveIndex(activeIndex - 1);
  }

  if (wasLoaded) {
    return (
      <div className="Slider" >
        <div className="Slider__bg"
          style={
            {
              backgroundImage: `linear-gradient(0deg, rgba(4, 30, 66, 0.5), rgba(4, 30, 66, 0.5)),url(${slides[activeIndex].backgroundImage})`
            }
          } />
        <div className="Slider__content">
          <SliderContent>
            {slides.map((slide: ISlide, i: number) => (
              <SliderItem key={i + 1} current={i === activeIndex} {...slide} />
            ))}
          </SliderContent>
          <ControlsContainer
            length={slides.length}
            current={activeIndex}
            leftFunction={prevSlide}
            rightFunction={nextSlide}
            singleFunction={setActiveIndex} />
        </div>
      </div>
    )
  } else {
    return <div className="Slider" />;
  }
}


export default Slider