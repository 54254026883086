import React from 'react';
import './ResourceHeading.scss';
import { Link } from 'gatsby';
import Container from '../../../common/components/Container/Container';
import { Constants } from '../../../common/constants';
import trackEvent from '../../../common/services/analytics.service';

interface IResourceHeading {
  title: string;
}

const ResourceHeading = (props: IResourceHeading) => {
  const { title } = props;
  const trackBackToHome = () => {
    trackEvent(Constants.Segment.Action.BackToHome, {
      action: Constants.Segment.Action.BackToHome,
      label: window.location.pathname,
      category: Constants.Segment.Category.Navigation
    })
  }
  return (
    <Container>
      <div className="ResourceHeading">
        <div className="ResourceHeading__first-block">
          <h1 className="ResourceHeading__title">
            {title}
          </h1>
          <p className="ResourceHeading__back">
            <Link to='/' onClick={trackBackToHome}>&lt; Back to home</Link>
          </p>
        </div>
      </div>
    </Container>
  );
};

export default ResourceHeading;